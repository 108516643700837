<template>
  <v-app :style="{'background': $vuetify.theme.themes['light'].background}">

    <!-- Logged in -->
    <div v-if="user && user.loggedIn">
      <v-app-bar app dense color="white" elevation="0">
        <v-layout wrap>
          <v-flex xs2 class="pt-3 pl-2" v-if="!$vuetify.breakpoint.xs">
            <h3 class="primary--text"><a :href="user && user.loggedIn ? '/search-resumes' : '/'" style="text-decoration: none"><span class="secondary--text">/ \</span>Volcano</a></h3>
          </v-flex>
          <v-flex xs10>
            <v-tabs show-arrows optional>
              <v-tab @click="signOut(false)">{{ $t('e') }}</v-tab>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-app-bar>
    </div>

    <!-- Not logged in -->
    <div v-else-if="this.$router.currentRoute.path !== '/'">
      <v-app-bar app dense color="white" elevation="0">
        <v-layout wrap>
          <v-flex xs6>
            <v-tabs show-arrows optional>
              <v-tab to="/demo-search-resumes">
                Search CVs
              </v-tab>
              <v-tab to="/demo-search-jobs">
                Search Jobs
              </v-tab>
            </v-tabs>
          </v-flex>
          <v-flex xs6 text-end>
            <v-btn to="/login">Sign in</v-btn>
            <v-btn to="/signup">Create account</v-btn>
          </v-flex>
        </v-layout>
      </v-app-bar>
    </div>

    <v-main>
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "user"
    }),
  }
};
</script>


<style>
  .v-btn {
    text-transform: none !important;
  }
</style>

