<template>
  <v-container fluid fill-height style="background: white">
    <v-layout align-center>
      <v-flex xs12 text-center>
        <v-layout wrap justify-center>
          <v-flex xs12 mb-12 pb-12 pt-6>
            <v-layout wrap justify-center>
              <v-flex text-right>
                <img :src="images.volcano" height="35px" class="mr-2">
              </v-flex>
              <v-flex text-left>
                <span style="font-size:1.8em;font-weight:500;">Volcano.hr</span>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 text-center mt-2>
                <div>
                  <span style="font-size:1.25em"><i>CV formatter for staffing and consulting companies</i></span>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 mt-6>
            <div style="font-size:1.9em; font-weight:450">
              <div>📨&nbsp;&nbsp;Send the CV of your candidate to <a :href="mailhref" style="color:black">cv@volcano.hr</a></div>
              <div class="mt-6">🪄&nbsp;&nbsp;We reply in 5 minutes with the CV formatted to your company's design</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout wrap mt-12 mb-12>
          <v-flex xs12 text-center>
            <v-btn large :href="mailhref" color="black" class="white--text">
              <span>Send a CV by email to try it</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-if="!isShowingHowItWorks" wrap mt-12 pt-12>
          <v-flex xs12 mt-12 pt-9>
            <v-btn small outlined rounded @click="showHowItWorks()">How it works</v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap mt-12 pt-12 mb-12 justify-center>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm5>
                <div>💌&nbsp;&nbsp;<b>You send a CV as attachment to cv@volcano.hr</b></div>
                <div>⬇️</div>
                <v-card flat outlined class="py-3 px-2 my-2">
                  <v-layout wrap justify-center>
                    <v-flex>
                      <div style="font-size:0.95em" class=" mt-2">
                        <div>🤖&nbsp;&nbsp;We automatically extract the data from the CV.</div>
                        <div class="my-1">⬇️</div>
                        <div>📄&nbsp;&nbsp;We create a Word document based on your company's template.</div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
                <div>⬇️</div>
                <div>📬&nbsp;&nbsp;<b>You get the new formatted CV version in your inbox under 5 minutes.</b></div>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center mt-12 pt-12>
              <v-flex xs11 sm5>
                <v-card flat outlined class="py-3 px-6">
                  <div style="font-size:0.9em" class="text-left">
                    <div>Also,</div>
                    <div>✏️&nbsp;&nbsp;You can edit your templates online.</div>
                    <div>🖇&nbsp;&nbsp;You can send us CVs in .pdf .doc .docx .png .jpg .txt .rtf or .html.</div>
                    <div>📑&nbsp;&nbsp;We send you a .docx file, so that you can edit it afterwards.</div>
                    <div>🔒&nbsp;&nbsp;<b>All your data is private and automatically deleted from our servers under 24h.</b></div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isShowingHowItWorks: false,
    images: {
      volcano: require('@/assets/volcano.png')
    },
  }),
  computed: {
    mailhref() {
      const subject = 'Transform a CV'
      
      const body = `Dear Artificial Intelligence,

Please find attached a CV, sent from my professional email address.

Have a good day !`

      return `mailto:cv@volcano.hr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    }
  },
  methods: {
    showHowItWorks() {
      this.isShowingHowItWorks = true
      this.scrollToBottom()
    },
    scrollToBottom() {
      setTimeout(() => {
        window.scroll({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }, 500)
    }
  }
}
</script>